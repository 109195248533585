import React, { useEffect, useState } from "react";
import "./CoursesList.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Button, Input, Dropdown, Modal, ModalClose, ModalDialog, Option, Sheet, Typography, Menu, MenuButton } from "@mui/joy";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import "../../Common/Common.css";
import { useSelector } from "react-redux";
import { fetchAllCoursesThunk } from "../../Store/actions/courseAction";
import { ToastType } from "../../Constants/toast-type";
import { showToast } from "../../Store/slices/commonSlice";
import WithAuth from "../utils/WithAuth";
import Loader from "../../Components/utils/Loader";
import useDebounce from "../../hooks/use-debounce";
import PaginationComponent from "../utils/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import { getFirstLetterCapitalized } from "../../helpers/text-formatter";
import { DateTime } from "luxon";
import ClearIcon from "@mui/icons-material/Clear";
import CreateCourseForm from "../Course/add-course/add-course";
import FilterOptions from "./filter-course-options";
import { UserRole } from "../../Constants/user-role";
import { fetchAllTenantsThunk } from "../../Store/actions/tenantAction";
import { fetchAllCoursesNameThunk } from "../../Store/actions/courseAction";
import { ReactComponent as FilterIcon } from "../../Assets/filter.svg";
import { clearAll } from "../../Store/slices/courseSlice";

const CoursesList = () => {
	const navigate = useNavigate();
	const [showAddCourseModal, setShowAddCourseModal] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [search, setSearch] = useState("");
	const debouncedValue = useDebounce(search, 500);
	const { user } = useSelector((state) => state.user);
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const dispatch = useDispatch();

	const { allCourses, loading, error, filters } = useSelector((state) => state.course);

	useEffect(() => {
		dispatch(clearAll());
		if (user?.role === UserRole.ADMIN) dispatch(fetchAllTenantsThunk());
		// dispatch(fetchAllCoursesNameThunk());
	}, []);

	useEffect(() => {
		if (debouncedValue) {
			if (currentPage !== 1) setCurrentPage(1);
			else {
				dispatch(fetchAllCoursesThunk({ search: debouncedValue, page: currentPage }));
			}
		}
	}, [debouncedValue]);

	useEffect(() => {
		if (debouncedValue) {
			if ((filters !== Object.keys(filters).length) === 0) {
				if (currentPage !== 1) {
					setCurrentPage(1);
					dispatch(fetchAllCoursesThunk({ search: debouncedValue, page: 1, ...filters }));
				} else {
					dispatch(fetchAllCoursesThunk({ search: debouncedValue, page: currentPage, ...filters }));
				}
			} else {
				dispatch(fetchAllCoursesThunk({ search: debouncedValue, page: currentPage }));
			}
		} else if (Object.values(filters).filter((val) => val != null).length !== 0) {
			dispatch(fetchAllCoursesThunk({ page: currentPage, ...filters }));
		} else {
			dispatch(fetchAllCoursesThunk({ page: currentPage }));
		}
	}, [currentPage, debouncedValue]);

	if (error["fetchAllCourses"]) {
		dispatch(showToast({ toastMessage: error["fetchAllCourses"], toastType: ToastType.ERROR }));
	}

	const handleSearchChange = (e) => {
		const value = e.target.value.trim(); // Trim the value here

		// Only set search if the value is non-empty or if it's completely cleared
		if (value !== "" || e.target.value === "") {
			setSearch(e.target.value);
		}
	};

	const handleFilterChange = () => {
		if (debouncedValue) {
			if (currentPage !== 1) {
				setCurrentPage(1);
				dispatch(fetchAllCoursesThunk({ search: debouncedValue, page: 1, ...filters }));
			} else {
				dispatch(fetchAllCoursesThunk({ search: debouncedValue, page: currentPage, ...filters }));
			}
		} else if (filters) {
			if (currentPage !== 1) {
				setCurrentPage(1);
				dispatch(fetchAllCoursesThunk({ page: 1, ...filters }));
			} else {
				dispatch(fetchAllCoursesThunk({ page: currentPage, ...filters }));
			}
		} else {
			dispatch(fetchAllCoursesThunk({ page: currentPage }));
			setCurrentPage(1);
		}
		setDropdownOpen(false);
	};

	const handleClearSearch = () => {
		setSearch("");
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const renderCourseCreateModal = () => {
		return (
			<Modal
				open={showAddCourseModal}
				onClose={() => {
					setShowAddCourseModal(false);
				}}
			>
				<ModalDialog sx={{ width: 600 }}>
					<ModalClose />
					<Typography level='h4'>Create New Course</Typography>
					<CreateCourseForm setShowAddCourseModal={setShowAddCourseModal} />
				</ModalDialog>
			</Modal>
		);
	};

	const renderCourseCard = (course) => {
		return (
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					p: "1rem",
					bgcolor: "white",
					cursor: "pointer",
					borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
				}}
				key={course.course_id}
				onClick={() => {
					navigate(`/course/${course.course_id}`);
				}}
			>
				<Box>
					<Typography
						sx={{ color: "#3838f1", fontWeight: "600", maxWidth: 500 }}
						noWrap
					>
						{getFirstLetterCapitalized(course.name)}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 4,
					}}
				>
					<Box>
						<Typography
							sx={{
								fontSize: ".8rem",
							}}
						>
							{DateTime.fromISO(course?.created_at).toFormat("dd LLL ''yy")}
						</Typography>
					</Box>
					<Box
						sx={{
							width: "60px",
						}}
					>
						<Typography
							sx={{
								fontSize: ".8rem",
								textAlign: "right",
							}}
						>
							{course?.topic_count[0]?.count || 0} {course?.topic_count[0]?.count > 1 ? "Topics" : "Topic"}
						</Typography>
					</Box>
					<Box>
						<Typography
							sx={{
								fontSize: ".8rem",
							}}
						>
							{getFirstLetterCapitalized(course?.difficulty_level?.toLowerCase())}
						</Typography>
					</Box>
				</Box>
			</Box>
		);
	};

	const renderCourses = () => {
		if (loading) {
			return (
				<div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "50vh" }}>
					<Loader />
				</div>
			);
		} else if (allCourses?.data?.data?.length > 0) {
			// dispatch(showToast({ toastMessage: "Courses loaded successfully", toastType: ToastType.SUCCESS }));
			return (
				<Box
					sx={{
						maxHeight: "58vh",
						overflowY: "auto",
						bgcolor: "white",
						borderRadius: "8px",
					}}
				>
					{allCourses.data?.data?.map((course, i) => renderCourseCard(course))}
				</Box>
			);
		} else {
			return (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "50vh",
					}}
				>
					No courses available
				</div>
			);
		}
	};

	const renderPagination = () => {
		return (
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					marginTop: "2rem",
				}}
			>
				<Box
					sx={{
						display: "flex",
						bgcolor: "white",
						justifyContent: "center",
						alignItems: "center",
						padding: " .5rem",
						borderRadius: "8px",
						gap: 5,
					}}
				>
					<PaginationComponent
						totalPage={allCourses?.data?.totalPages}
						onPageChange={handlePageChange}
						currentPage={currentPage}
					/>
				</Box>
			</Box>
		);
	};

	return (
		<div className='courses-list-container'>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					marginBottom: "1.5rem",
				}}
			>
				<Typography sx={{ marginRight: "0.2rem", fontWeight: 600, fontSize: "1.5rem" }}>Courses {allCourses?.data?.count}</Typography>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 3,
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							border: "1px solid rgba(0, 0, 0, 0.1)",
							borderRadius: "8px",

							padding: ".1rem .5rem",
						}}
					>
						<SearchIcon />
						<Input
							placeholder='Search Courses'
							value={search}
							sx={{
								bgcolor: "transparent",
								boxShadow: "none",
								border: "none",
								outline: "none",
								"--Input-focusedThickness": "0rem",
							}}
							onChange={handleSearchChange}
						/>
					</Box>

					{search !== "" && (
						<Box sx={{ display: "flex", gap: 10 }}>
							<Button
								variant='primary'
								sx={{
									border: "1px solid rgba(0, 0, 0, 0.1)",
									borderRadius: "8px",
									padding: ".1rem .5rem",
								}}
								onClick={handleClearSearch}
							>
								<ClearIcon sx={{ color: "red" }} />
							</Button>
						</Box>
					)}

					{user?.role !== UserRole.TEACHER && (
						<Dropdown open={dropdownOpen}>
							<MenuButton
								sx={{
									display: "flex",
									alignItems: "center",
									border: "1px solid rgba(0, 0, 0, 0.1)",
									borderRadius: "8px",
									padding: ".5rem 1rem",
									gap: 1,
								}}
								onClick={() => setDropdownOpen((prev) => !prev)}
								variant='outlined'
							>
								<FilterIcon />

								<Typography
									sx={{
										fontWeight: 600,
										fontSize: "14px",
										color: "#050519",
									}}
								>
									Filter{" "}
									{Object.values(filters).filter((val) => val !== null)?.length > 0 && (
										<Typography
											sx={{
												color: "#fff",
												p: ".1rem .4rem",
												// bgcolor: "#D48134",
												bgcolor: "#3838f1",
												borderRadius: "5px",
												ml: ".5rem",
												fontWeight: 600,
											}}
										>
											{Object.values(filters).filter((val) => val !== null)?.length}
										</Typography>
									)}
								</Typography>
							</MenuButton>
							<Menu>
								<FilterOptions handleFilter={handleFilterChange} />
							</Menu>
						</Dropdown>
					)}

					<Box sx={{ display: "flex", gap: 10 }}>
						<Button
							variant='primary'
							sx={{ bgcolor: "#3838F1" }}
							onClick={() => {
								setShowAddCourseModal(true);
							}}
						>
							<Typography sx={{ width: "fit-content", color: "white" }}>Create New Course</Typography>
						</Button>
					</Box>
				</Box>
			</Box>
			{renderCourses()}
			{renderPagination()}
			{renderCourseCreateModal()}
		</div>
	);
};

export default WithAuth(CoursesList);
